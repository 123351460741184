import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { PageHero } from 'components/blocks'
import { Layout } from 'components/global'
import VenmoIcon from 'svgs/venmo.svg'

export default function Donate() {
  const meta = {
    title: `Donate | Dorothy Dandridge`,
  }
  return (
    <Layout title={meta.title} pageIsDonate>
      <PageHero title="Donate" />
      <div className="bg-black">
        <div className="w-11/12 min-h-[50vh] mx-auto container py-12 text-left md:text-[20px]">
          <div className="max-w-[900px] mx-auto">
            <div className="text-center mx-auto">
              <div className="p-4 bg-white inline-block text-center mx-auto	">
                <StaticImage
                  src="../images/logos/Healing-tree.png"
                  alt=""
                  className="drop-white	"
                />
              </div>
            </div>
            <div className="text-center mt-6">
              <p>
                We proudly partner with Healing TREE, a 501(c)(3) not-for-profit
                charitable corporation who act as our fiscal sponsor. To make a
                tax-deductible contribution for Dorothy Dandridge! The Musical
                click{' '}
                <a
                  href="https://healingtree.app.neoncrm.com/forms/dorothy-dandridge-the-musical"
                  className="text-gold font-bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  DONATE
                </a>
                .
              </p>
              <p>
                Healing TREE advocates healing from abuse and trauma rather than
                coping with the symptoms in order to transform lives and,
                ultimately, society. They achieve this by providing
                trauma-focused resources and education and by producing and
                partnering with relevant film, television, and theatre,
                empowering the social change necessary to create a healing
                movement. To learn more about healing trauma, please visit our
                partner{' '}
                <a
                  href="https://healingtreenonprofit.org"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gold font-bold"
                >
                  Healing TREE
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
